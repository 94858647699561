// import React, { lazy, Suspense } from 'react'

// import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import Index from './components/News/Index.jsx'



// const HomePage =lazy(()=>import('./components/HomePage'))
// const AboutPage= lazy(()=>import('./components/ABout/AboutPage'))
// const Gallary= lazy(()=>import('./components/Gallarys/Index.jsx'))
// const Blogses =lazy(()=>import('./components/Blogses/Index.jsx'))
// const Details=lazy(()=>import('./components/BlogDetail/Index.jsx'))
// const Contact = lazy(()=>import('./components/Contect/Index.jsx'))
// const NewsDetail= lazy(()=>import('./components/NewsDetail/Index.jsx'))
// export default function App() {
//   return (
//     <div>
//       <BrowserRouter>
//       <Suspense fallback={<div className='w-[100vw] h-[100vh] text-center justify-center items-center  text-2xl '> Loading..</div>}>

//       <Routes>
//         <Route path='/' element={<HomePage/>}/>
//         <Route path='/about' element={<AboutPage/>}/>
//         <Route path='/contact' element={<Contact/>}/>
        
//         <Route path='/gallery' element={<Gallary/>}/>
//         <Route path='/blog' element={<Blogses/>}/>
//         <Route path='/blog/:id' element={<Details/>}/>
//         <Route path='/news' element={<Index/>}/>
//         <Route path='/news/:id' element={<NewsDetail/>}/>
   
//     </Routes>
//       </Suspense>
//       </BrowserRouter>
//     </div>
//   )
// }
// App.js
// App.js
// App.js
// App.js
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const App = () => {
 
  // Define a key to store the target date in localStorage
  const STORAGE_KEY = "countdownDate";
 
 
  // Check if a target date exists in localStorage; if not, set one for 10 days from now
  const getOrCreateTargetDate = () => {
    const savedDate = localStorage.getItem(STORAGE_KEY);
    if (savedDate) {
      return new Date(savedDate);
    }
    const newTargetDate = new Date();
    newTargetDate.setDate(newTargetDate.getDate() + 23);

    localStorage.setItem(STORAGE_KEY, newTargetDate.toISOString());
    return newTargetDate;
  };

  const targetDate = getOrCreateTargetDate();
  const [timeLeft, setTimeLeft] = useState(targetDate.getTime() - new Date().getTime());

  useEffect(() => {
    const timer = setInterval(() => {
      const remainingTime = targetDate.getTime() - new Date().getTime();
      setTimeLeft(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(timer);
        setTimeLeft(0);
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [targetDate]);

  // Format time into days, hours, minutes, and seconds
  const formatTime = (milliseconds) => {
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const seconds = Math.floor((milliseconds / 1000) % 60);

    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } = formatTime(timeLeft);

  return (
    <motion.div 
      className="flex flex-col relative z-30  justify-start items-start h-screen  text-black text-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="bg-gradient-to-l to-[#e2e2e2fa] absolute z-10 top-0 w-[100%] h-[100%] from-[#ffffffaf] " />
      <div className="mt-[2rem] z-30 ml-[2rem] w-[5rem]">
        <img src={require('./DEPL  LOGO.png')} alt="" />
      </div>
    {/*  */}
    {/* <div ref={vantaRef} className="absolute top-0 left-0 w-full h-full" /> */}
      <div  className="absolute left-0  w-[100%] bg-slate-100 h-[100%] -z-10" >
        <img src={require('./02.0298bfa3921dd5cee733.png')} className="w-[100%] h-[100%] object-cover" alt="" />
      </div>
      {/* <div className="absolute right-0 bottom-0 -z-20  md:w-[55%] overflow-hidden">
        <img src={require('./bg_1.jpg')} className="w-[100%]" alt="" />
      </div> */}
      <div className="w-[100%] z-30 flex h-[100%] flex-col gap-5 items-center text-[#252525] text-center justify-center">
      <motion.h1
        className="md:text-5xl max-md:text-4xl  text-[#252525] text-center  font-bold mb-8"
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >We are excited to announce that our brand-new<br/> website is launching soon
        
      </motion.h1>
      <motion.h1
        className="text-4xl text-center   font-bold mb-4"
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
         <span>STAY TUNED</span> <br/>for the big reveal!
      </motion.h1>
      <motion.div
        className="grid md:grid-cols-4 max-md:grid-cols-2 gap-4 text-4xl font-semibold"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="flex border-[1px] border-blue-500 p-2 flex-col items-center">
          <span className="text-blue-700 font-bold">{days}</span>
          <span className="text-xl">Days</span>
        </div>
        <div className="flex flex-col  border-[1px] border-blue-500 p-2 items-center">
          <span className="text-blue-700 font-bold">{hours}</span>
          <span className="text-xl">Hours</span>
        </div>
        <div className="flex flex-col border-[1px] border-blue-500 p-2 items-center">
          <span className="text-blue-700 font-bold">{minutes}</span>
          <span className="text-xl">Minutes</span>
        </div>
        <div className="flex flex-col border-[1px] border-blue-500 p-2 items-center">
          <span className="text-blue-700 font-bold">{seconds}</span>
          <span className="text-xl">Seconds</span>
        </div>
      </motion.div>
      {timeLeft === 0 && (
        <motion.h2
          className="text-3xl font-medium mt-8"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          Time’s Up!
        </motion.h2>
      )}
      </div>
      
      <div className="border-t-[1px] text-black z-40 w-[100%] border-blue-500 shadow-md- py-[1rem]
       font-bold tracking-wider text-xl text-center">
        <p>Dambal Enterprises Private Limited</p>

      </div>
    </motion.div>
  );
};

export default App;
